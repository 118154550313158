.TabsTrigger {
  transition: background-color 50ms linear;
}
.TabsTrigger:hover {
  box-shadow:
    inset 0 -1px 0 0 currentColor,
    0 1px 0 0 currentColor;
}
.TabsTrigger[data-state="active"] {
  box-shadow:
    inset 0 -1px 0 0 currentColor,
    0 1px 0 0 currentColor;
}
