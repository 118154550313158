.loading-indicator {
  display: inline-block;
  position: relative;
  width: 42px;
  height: 42px;
}
.loading-indicator div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 5px;
  border-width: 5px;
  border-style: solid;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #914dec transparent transparent transparent;
}
.white div {
  border-color: #ffffff transparent transparent transparent;
}
.black div {
  border-color: #000000 transparent transparent transparent;
}
.purple div {
  border-color: #914dec transparent transparent transparent;
}
.sm {
  width: 18px;
  height: 18px;
}
.sm div {
  margin: 0;
  width: 18px;
  height: 18px;
  border-width: 3px;
}
.loading-indicator div:nth-child(1) {
  animation-delay: -0.45s;
}
.loading-indicator div:nth-child(2) {
  animation-delay: -0.3s;
}
.loading-indicator div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
