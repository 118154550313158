/* Next Card Animations */

@keyframes carouselNextKeys {
  0% {
    transform: rotateY(0) translateX(0) translateZ(0px);
  }
  50% {
    transform: rotateY(-40deg) translateX(-120px) translateZ(-30px);
  }
  100% {
    transform: rotateY(-80deg) translateX(-180px) translateZ(-60px);
  }
}

.carouselNext {
  animation: carouselNextKeys 0.2s linear;
}

@keyframes carouselNextTwoKeys {
  0% {
    left: 20px;
    transform: translateZ(-60px);
  }
  50% {
    left: 10px;
    transform: translateZ(-30px);
  }
  100% {
    left: 0;
    transform: translateZ(0px);
    z-index: 3;
  }
}

.carouselNextTwo {
  animation: carouselNextTwoKeys 0.2s linear;
}

@keyframes carouselNextThreeKeys {
  0% {
    left: 40px;
    transform: translateZ(-120px);
  }
  50% {
    left: 30px;
    transform: translateZ(-90px);
  }
  100% {
    left: 20px;
    transform: translateZ(-60px);
    z-index: 2;
  }
}

.carouselNextThree {
  animation: carouselNextThreeKeys 0.2s linear;
}

/* Previous Card Animations */

@keyframes carouselPrevKeys {
  0% {
    left: 0px;
    transform: translateZ(0px);
  }
  50% {
    left: 10px;
    transform: translateZ(-30px);
  }
  100% {
    left: 20px;
    transform: translateZ(-60px);
    z-index: 2;
  }
}

.carouselPrev {
  animation: carouselPrevKeys 0.2s linear;
}

@keyframes carouselPrevTwoKeys {
  0% {
    left: 20px;
    transform: translateZ(-60px);
  }
  50% {
    left: 30px;
    transform: translateZ(-90px);
  }
  100% {
    left: 40px;
    transform: translateZ(-120px);
    z-index: 1;
  }
}

.carouselPrevTwo {
  animation: carouselPrevTwoKeys 0.2s linear;
}

@keyframes carouselPrevLastKeys {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.carouselPrevLast {
  animation: carouselPrevLastKeys 0.2s linear;
}

@keyframes carouselPrevThreeKeys {
  0% {
    transform: rotateY(-40deg) translateX(-140px) translateZ(-60px);
  }
  50% {
    transform: rotateY(-10deg) translateX(-90px) translateZ(-30px);
  }
  100% {
    left: 0px;
    transform: rotateY(0) translateX(0) translateZ(0px);
    z-index: 3;
  }
}

.carouselPrevThree {
  animation: carouselPrevThreeKeys 0.2s linear;
}
